import './ImageCarousel.scss'
import React, {Component} from 'react';
import {Carousel, CarouselControl, CarouselItem} from 'reactstrap';
import spaceImg from '../res/img_1_space.jpg';
import {ReactComponent as IconArrowDown} from "../res/icon_arrow-down.svg";

function importAllImgs(r) {
  return r.keys().map(r);
}

const images = importAllImgs(require.context('../res/imgs/', false, /\.(png|jpe?g|JPG)$/));

const items = [];
images.forEach(img => {
  items.push({
    src: img,
    altText: 'Slide ',
    caption: 'Slide'
  })
})

export default class ImageCarousel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      isPaused: true
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({activeIndex: nextIndex});
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({activeIndex: nextIndex});
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({activeIndex: newIndex});
  }

  render() {
    const {activeIndex} = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img src={item.src} alt={item.altText}/>
        </CarouselItem>
      );
    });

    const PausedView = () => {
      return (
        <div className={'PausedView'}
             onClick={() => this.setState({isPaused: false})}
             style={{backgroundImage: `url(${spaceImg})`}}>
          <h1 className={'content'}>Start?</h1>
        </div>)
    }

    return (
      <div className='ImageCarousel'>
        {this.state.isPaused ?
          <div>
            <PausedView/>
            <div className={'arrow-container'}>
              <p>First: click on start. But there is something more down here</p>
              <IconArrowDown className={'arrow-down'}/>
            </div>
          </div> :
          <div className={'content'}>
            <div className='textContainer'>
              <h2>something more than 9 months...</h2>
            </div>
            <Carousel activeIndex={activeIndex}
                      next={this.next}
                      previous={this.previous}>

              {slides}

              <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous}/>
              <CarouselControl direction="next" directionText="Next" onClickHandler={this.next}/>

            </Carousel>

            <div className='textContainer'>
              <h2>...of love and awesomeness</h2>
              <p>by Fabiano</p>
            </div>
          </div>
        }
      </div>
    );
  }
}
