import React from 'react';
import './Header.scss';
import imgSpace from '../res/img_1_space.jpg';
import imgNola from '../res/img_2_nola.png';
import imgClouds from '../res/img_3_clouds.png';
import {ReactComponent as IconArrowDown} from '../res/icon_arrow-down.svg';

export default function Header() {

  function handleScroll() {
    // window.scrollTo({behavior:  'smooth', top: window.innerHeight})
  }

  return (
    <div className={'Header'} style={{backgroundImage: `url(${imgSpace})`}}>
      <div className={'text-container'}>
        <h1>For the best Mama</h1>
        <h1>in the world</h1>

      </div>

      <img className={'nola'} src={imgNola} alt={'nola'}/>
      <img className={'clouds'} src={imgClouds} alt={'clouds'}/>

      <div className={'button-container'} onClick={handleScroll}>
        <h3>Hallo?</h3>
        <IconArrowDown className={'arrow-down'}/>
      </div>
    </div>
  )
}
