import React from 'react';
import spaceImg from '../res/img_1_space.jpg';
import './Footer.scss';

export default function Footer() {

  return (
    <div className={'Footer'} style={{backgroundImage: `url(${spaceImg})`}}>
      <div className={'content'}>
        <p>Oh boy, und no e Guetschyn...</p>
        <p>Für fantastischs Dinieren – ohne GaultMillau gehen wir nicht hin.</p>
        <p><a target={'blank'} href={'https://www.gaultmillau.ch/restaurants-suche/Z%C3%BCrich'}>Liste</a> zum usläse. I love you!!!</p>
      </div>
    </div>
  )

}
