import React from 'react';
import './App.scss';
import ImageCarousel from "./components/ImageCarousel";
import {FullPage, Slide} from "react-full-page";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <FullPage>
        <Slide>
          <Header/>
        </Slide>
        <Slide>
          <ImageCarousel/>
        </Slide>
        <Slide>
          <Footer/>
        </Slide>
      </FullPage>
    </div>
  );
}

export default App;


